import {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
  useState
} from 'react';
import { loadMapkit } from '@triplabo/web/utils';
import styles from './map.module.css';

export interface IMapProps {
  token: string;
  options?: mapkit.MapConstructorOptions;
  onLoad?: () => void;
}

export interface IMapRef {
  map: mapkit.Map | null;
}

const defaultControls = {
  showsZoomControl: true,
  showsMapTypeControl: true
};

const Map = forwardRef<IMapRef, IMapProps>(
  ({ token, options, onLoad }, ref) => {
    const mapRef = useRef<HTMLDivElement>(null);
    const loaded = useRef<boolean>(false);
    const [map, setMap] = useState<mapkit.Map | null>(null);

    useEffect(() => {
      if (mapRef.current) {
        loadMapkit(token).then(() => {
          if (!loaded.current) {
            const map = new mapkit.Map(mapRef.current!, {
              ...options,
              ...defaultControls
            });
            setMap(map);
            loaded.current = true;
          }
        });
      }

      return () => {
        if (map) {
          map.destroy();
          loaded.current = false;
        }
      };
    }, [map, token, options]);

    useEffect(() => {
      if (map) {
        onLoad?.();
      }
    }, [map, onLoad]);

    useImperativeHandle(
      ref,
      () => ({
        map
      }),
      [map]
    );

    return <div className={styles.map} ref={mapRef}></div>;
  }
);

export default Map;
