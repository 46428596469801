export default {
  views: {
    dashboard: {
      title: '仪表板'
    },
    home: {
      greeting: '嗨，我们出发吧!',
      loginButton: '立即登录',
      demoPay: '模拟支付',
      destinationPlaceholder: '您要去哪里？',
      pickUp: '上车',
      recommendPickup: '推荐上车点',
      categories: {
        recommend: '推荐',
        transportation: '交通',
        attraction: '景点',
        experience: '体验'
      }
    },
    pickup: {
      searchPlaceholder: '请输入上车点',
      recommendTitle: '为您推荐附近的上车点',
      historyTitle: '历史记录',
      empty: '暂无数据',
      emptyTip: '换个词试试，或用"地图选点"即刻发单',
      emptyButton: '地图选点'
    },
    dropOff: {
      searchPlaceholder: '请输入下车点',
      recommendTitle: '为您推荐附近的上车点',
      historyTitle: '历史记录',
      empty: '暂无数据',
      emptyTip: '换个词试试，或用“地图选点”即刻发单',
      emptyButton: '地图选点',
      emptyRecord: '暂无历史数据',
      emptyRecordTip: '快去搜索框输入目的地吧'
    },
    profile: {
      order: '我的行程',
      service: '客服中心',
      logout: '退出登录',
      userName: '微信用户',
      goToLogin: '登录微信账户'
    },
    order: {
      all: '全部',
      succeed: '已完成',
      canceled: '已取消',
      empty: '暂无订单',
      emptyTip: '快去呼叫用车吧',
      noMore: '没有更多了',
      carType: {
        economy: '经济型',
        business: '商务型',
        luxury: '豪华型'
      },
      status: {
        PENDING: '待支付',
        SUCCEED: '已完成',
        CANCELED: '已取消',
        UNKNOWN: '未知'
      },
      money: '{{amount}} 元'
    },
    payment: {
      title: '预付车费',
      description:
        '为确保顺利发单，请您按照预估价支付车费，行程结束后按实际费用多退少补。',
      wechat: '微信支付',
      wechatDesc: '使用人民币支付，适用于大陆微信用户',
      agreement: '我已认真阅读并同意',
      agreementLink: '《预付款协议》',
      confirm: '预支付{{amount}}元，立即叫车',
      money: '{{amount}}元'
    }
  }
};
