let loadingPromise: Promise<void> | null = null;

export function loadMapkit(token: string, language = 'zh-Hans') {
  if (loadingPromise !== null) {
    return loadingPromise;
  }

  loadingPromise = new Promise(resolve => {
    const script = document.createElement('script');
    script.addEventListener(
      'load',
      () => {
        mapkit.init({
          authorizationCallback: done => done(token),
          language
        });

        resolve();
      },
      { once: true }
    );
    script.src = 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js';
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);
  });
  return loadingPromise;
}
