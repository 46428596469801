import { action, observable } from 'mobx';

export class MapKit {
  @observable accessor map: mapkit.Map | null = null;

  @action
  setMap(map: mapkit.Map) {
    this.map = map;
  }
}

const mapKit = new MapKit();

export default mapKit;
