import { action, observable } from 'mobx';

export interface Location {
  name?: string;
  address?: string;
  latitude: number;
  longitude: number;
}

export interface TripInfo {
  pickupLocation: Location | null;
  dropoffLocation: Location | null;
}

export class TripStore {
  @observable accessor currentTrip: TripInfo = {
    pickupLocation: null,
    dropoffLocation: null
  };

  @action
  setPickupLocation(location: Location) {
    this.currentTrip.pickupLocation = location;
  }

  @action
  setDropoffLocation(location: Location) {
    this.currentTrip.dropoffLocation = location;
  }

  @action
  clearTrip() {
    this.currentTrip = {
      pickupLocation: null,
      dropoffLocation: null
    };
  }
}

const trip = new TripStore();

export default trip;
