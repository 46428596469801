import React from 'react';
import mapInstance, { MapKit } from './modules/mapkit';
import auth from './modules/auth';
import trip from './modules/trip';

export type RootStore = {
  mapInstance: MapKit;
  auth: typeof auth;
  trip: typeof trip;
};

export const store: RootStore = {
  mapInstance,
  auth,
  trip
};

const StoreContext = React.createContext<RootStore>(store);

export const StoreProvider = StoreContext.Provider;

export const useStore = () => React.useContext(StoreContext);

export { mapInstance, auth, trip };
