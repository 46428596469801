import { action, observable } from 'mobx';
export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

export class AuthStore {
  @observable accessor accessToken: string | null =
    localStorage.getItem(ACCESS_TOKEN);
  @observable accessor refreshToken: string | null =
    localStorage.getItem(REFRESH_TOKEN);

  @action
  setAccessToken(accessToken: string | null) {
    if (accessToken) {
      this.accessToken = accessToken;
      localStorage.setItem(ACCESS_TOKEN, accessToken);
    }
  }

  @action
  setRefreshToken(refreshToken: string | null) {
    if (refreshToken) {
      this.refreshToken = refreshToken;
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
    }
  }

  @action
  clearTokens() {
    this.accessToken = null;
    this.refreshToken = null;
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  }
}

const auth = new AuthStore();

export default auth;
