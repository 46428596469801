import { message } from '@linktivity/link-ui';
import { Configuration } from '@linktivity/triplabo-taxi';
import { auth } from '@triplabo/web/stores';

const BASE_PATH = import.meta.env.VITE_APP_WEB_API;

const config = new Configuration({
  basePath: BASE_PATH,
  middleware: [
    {
      pre: async context => {
        const requestHeaders: HeadersInit = new Headers({
          'Content-Type': 'application/json'
        });
        // 每次请求时实时获取最新的token
        requestHeaders.append(
          'grpc-metadata-auth-token',
          auth.accessToken || ''
        );
        context.init.headers = requestHeaders;
      },
      post: async context => {
        if (!context.response.ok) {
          // handle error
          const data = await context.response.json();
          message.error(
            data.common.error_description || data.common.error_code,
            10
          );
        }
      }
    }
  ]
});

export default config;
