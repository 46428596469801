import { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { mapInstance } from '@triplabo/web/stores';
import { Map, IMapRef } from '@triplabo/web/components';

const token = import.meta.env.VITE_APP_MAPKIT_TOKEN;
const region = {
  latitude: 35.6588168,
  longitude: 139.7455603,
  latitudeDelta: 0.001,
  longitudeDelta: 0.001
};

const MapLayout: React.FC = observer(() => {
  const mapRef = useRef<IMapRef>(null);

  const onLoad = () => {
    if (mapRef.current?.map) {
      const map = mapRef.current?.map;
      const initRegion = new mapkit.CoordinateRegion(
        new mapkit.Coordinate(region.latitude, region.longitude),
        new mapkit.CoordinateSpan(region.latitudeDelta, region.longitudeDelta)
      );
      map.region = initRegion;
      mapInstance.setMap(map);
    }
  };

  return <Map token={token} ref={mapRef} onLoad={onLoad} />;
});

export default MapLayout;
